import Icon from "@ant-design/icons"


export const Successfully = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.5 0C37.4636 0 42.1609 1.13082 46.358 3.14781C44.64 4.50697 43.0471 5.81176 41.5629 7.06762C38.7358 6.04009 35.6859 5.48012 32.5054 5.48012C25.1823 5.48012 18.5496 8.44852 13.7545 13.2491C8.95396 18.0496 5.98556 24.6769 5.98556 32C5.98556 39.3231 8.95396 45.9504 13.7545 50.7509C18.555 55.5515 25.1823 58.5199 32.5054 58.5199C39.8286 58.5199 46.4613 55.5515 51.2564 50.7509C56.0569 45.9504 59.0253 39.3231 59.0253 32C59.0253 30.2603 58.8568 28.5532 58.536 26.9059C59.9115 25.1118 61.3196 23.3231 62.7603 21.5508C63.8911 24.8236 64.5054 28.3411 64.5054 32C64.5054 40.8345 60.9227 48.8372 55.1327 54.6273C49.3427 60.4173 41.34 64 32.5054 64C23.6709 64 15.6682 60.4173 9.87819 54.6273C4.08274 48.8372 0.5 40.8345 0.5 32C0.5 23.1655 4.08274 15.1628 9.87275 9.37275C15.6628 3.58274 23.6655 0 32.5 0ZM17.5928 26.7428L25.3998 26.6395L25.9815 26.7917C27.5581 27.6996 29.0423 28.738 30.4286 29.9123C31.429 30.7604 32.3858 31.6847 33.2938 32.685C36.0936 28.178 39.0783 24.0408 42.2316 20.2351C45.6838 16.0652 49.3481 12.2813 53.1973 8.82909L53.9584 8.53551H62.4776L60.7596 10.4438C55.4806 16.3099 50.691 22.3717 46.3634 28.6239C42.0359 34.8814 38.165 41.3401 34.7236 47.9891L33.6526 50.055L32.6685 47.9511C30.8527 44.053 28.6781 40.4757 26.0848 37.279C23.4915 34.0822 20.4742 31.2443 16.9567 28.8304L17.5928 26.7428Z" fill="#00AB6F" />
  </svg>

)




const Dashboard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3143_14669)">
      <path d="M20.38 8.57012L19.15 10.4201C19.7432 11.6032 20.0336 12.915 19.9952 14.2378C19.9568 15.5607 19.5908 16.8534 18.93 18.0001H5.06999C4.21116 16.5102 3.85528 14.7832 4.05513 13.0752C4.25497 11.3671 4.9999 9.76895 6.17947 8.51755C7.35904 7.26615 8.91046 6.42816 10.6037 6.12782C12.297 5.82747 14.042 6.08076 15.58 6.85012L17.43 5.62012C15.5465 4.41234 13.3123 3.87113 11.0849 4.08306C8.85744 4.29499 6.76543 5.24782 5.14348 6.78913C3.52153 8.33045 2.46335 10.3712 2.13821 12.5849C1.81306 14.7987 2.23974 17.0575 3.34999 19.0001C3.5245 19.3024 3.77508 19.5537 4.07682 19.7292C4.37856 19.9046 4.72096 19.998 5.06999 20.0001H18.92C19.2724 20.0015 19.6189 19.9098 19.9245 19.7342C20.2301 19.5586 20.4838 19.3053 20.66 19.0001C21.5814 17.404 22.0438 15.5844 21.9961 13.7421C21.9485 11.8998 21.3926 10.1064 20.39 8.56012L20.38 8.57012Z" fill="#72849A" />
      <path d="M10.59 15.4099C10.7757 15.5959 10.9963 15.7434 11.2391 15.844C11.4819 15.9447 11.7422 15.9965 12.005 15.9965C12.2678 15.9965 12.5281 15.9447 12.7709 15.844C13.0137 15.7434 13.2342 15.5959 13.42 15.4099L19.08 6.91992L10.59 12.5799C10.404 12.7657 10.2565 12.9862 10.1559 13.229C10.0552 13.4718 10.0034 13.7321 10.0034 13.9949C10.0034 14.2578 10.0552 14.518 10.1559 14.7608C10.2565 15.0036 10.404 15.2242 10.59 15.4099Z" fill="#72849A" />
    </g>
    <defs>
      <clipPath id="clip0_3143_14669">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

)
const UserManagement = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.67 9.12988C17.04 10.0599 18 11.3199 18 12.9999V15.9999H22V12.9999C22 10.8199 18.43 9.52988 15.67 9.12988Z" fill="#72849A" />
    <path d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C13.53 0 13.09 0.0999998 12.67 0.24C13.5 1.27 14 2.58 14 4C14 5.42 13.5 6.73 12.67 7.76C13.09 7.9 13.53 8 14 8Z" fill="#72849A" />
    <path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2Z" fill="#72849A" />
    <path d="M8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9ZM14 14H2V13.01C2.2 12.29 5.3 11 8 11C10.7 11 13.8 12.29 14 13V14Z" fill="#72849A" />
  </svg>

)
const AccountManagement = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 14V13.35C2 13.01 2.16 12.69 2.41 12.54C4.1 11.53 6.03 11 8 11C8.03 11 8.05 11 8.08 11.01C8.18 10.31 8.38 9.64 8.67 9.03C8.45 9.01 8.23 9 8 9C5.58 9 3.32 9.67 1.39 10.82C0.51 11.34 0 12.32 0 13.35V16H9.26C8.84 15.4 8.51 14.72 8.29 14H2Z" fill="#72849A" />
    <path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2Z" fill="#72849A" />
    <path d="M18.75 12C18.75 11.78 18.72 11.58 18.69 11.37L19.83 10.36L18.83 8.63L17.38 9.12C17.06 8.85 16.7 8.64 16.3 8.49L16 7H14L13.7 8.49C13.3 8.64 12.94 8.85 12.62 9.12L11.17 8.63L10.17 10.36L11.31 11.37C11.28 11.58 11.25 11.78 11.25 12C11.25 12.22 11.28 12.42 11.31 12.63L10.17 13.64L11.17 15.37L12.62 14.88C12.94 15.15 13.3 15.36 13.7 15.51L14 17H16L16.3 15.51C16.7 15.36 17.06 15.15 17.38 14.88L18.83 15.37L19.83 13.64L18.69 12.63C18.72 12.42 18.75 12.22 18.75 12ZM15 14C13.9 14 13 13.1 13 12C13 10.9 13.9 10 15 10C16.1 10 17 10.9 17 12C17 13.1 16.1 14 15 14Z" fill="#72849A" />
  </svg>

)
const InquiryManagement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3143_14675)">
      <path d="M11 23.59V19.99C5.99 19.73 2 15.57 2 10.5C2 5.26 6.26 1 11.5 1C16.74 1 21 5.26 21 10.5C21 15.45 17.56 20.43 12.43 22.9L11 23.59ZM11.5 3C7.36 3 4 6.36 4 10.5C4 14.64 7.36 18 11.5 18H13V20.3C16.64 18 19 14.22 19 10.5C19 6.36 15.64 3 11.5 3ZM10.5 14.5H12.5V16.5H10.5V14.5ZM12.5 13H10.5C10.5 9.75 13.5 10 13.5 8C13.5 6.9 12.6 6 11.5 6C10.4 6 9.5 6.9 9.5 8H7.5C7.5 5.79 9.29 4 11.5 4C13.71 4 15.5 5.79 15.5 8C15.5 10.5 12.5 10.75 12.5 13Z" fill="#72849A" />
    </g>
    <defs>
      <clipPath id="clip0_3143_14675">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

)

const AttendanceManagement = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 22q-.825 0-1.413-.587Q3 20.825 3 20V6q0-.825.587-1.412Q4.175 4 5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588Q21 5.175 21 6v14q0 .825-.587 1.413Q19.825 22 19 22Zm0-2h14V10H5v10ZM5 8h14V6H5Zm0 0V6v2Zm7 6q-.425 0-.712-.288Q11 13.425 11 13t.288-.713Q11.575 12 12 12t.713.287Q13 12.575 13 13t-.287.712Q12.425 14 12 14Zm-4 0q-.425 0-.713-.288Q7 13.425 7 13t.287-.713Q7.575 12 8 12t.713.287Q9 12.575 9 13t-.287.712Q8.425 14 8 14Zm8 0q-.425 0-.712-.288Q15 13.425 15 13t.288-.713Q15.575 12 16 12t.712.287Q17 12.575 17 13t-.288.712Q16.425 14 16 14Zm-4 4q-.425 0-.712-.288Q11 17.425 11 17t.288-.712Q11.575 16 12 16t.713.288Q13 16.575 13 17t-.287.712Q12.425 18 12 18Zm-4 0q-.425 0-.713-.288Q7 17.425 7 17t.287-.712Q7.575 16 8 16t.713.288Q9 16.575 9 17t-.287.712Q8.425 18 8 18Zm8 0q-.425 0-.712-.288Q15 17.425 15 17t.288-.712Q15.575 16 16 16t.712.288Q17 16.575 17 17t-.288.712Q16.425 18 16 18Z" fill='#72849A' /></svg>
)

const TaskManagement = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4917 0.166992H5.50832C2.47499 0.166992 0.666656 1.97533 0.666656 5.00866V11.992C0.666656 15.0253 2.47499 16.8337 5.50832 16.8337H12.4917C15.525 16.8337 17.3333 15.0253 17.3333 11.992V5.00866C17.3333 1.97533 15.525 0.166992 12.4917 0.166992ZM7.30832 10.917L5.43332 12.792C5.30832 12.917 5.14999 12.9753 4.99166 12.9753C4.83332 12.9753 4.66666 12.917 4.54999 12.792L3.92499 12.167C3.67499 11.9253 3.67499 11.5253 3.92499 11.2837C4.16666 11.042 4.55832 11.042 4.80832 11.2837L4.99166 11.467L6.42499 10.0337C6.66666 9.79199 7.05832 9.79199 7.30832 10.0337C7.54999 10.2753 7.54999 10.6753 7.30832 10.917ZM7.30832 5.08366L5.43332 6.95866C5.30832 7.08366 5.14999 7.14199 4.99166 7.14199C4.83332 7.14199 4.66666 7.08366 4.54999 6.95866L3.92499 6.33366C3.67499 6.09199 3.67499 5.69199 3.92499 5.45033C4.16666 5.20866 4.55832 5.20866 4.80832 5.45033L4.99166 5.63366L6.42499 4.20033C6.66666 3.95866 7.05832 3.95866 7.30832 4.20033C7.54999 4.44199 7.54999 4.84199 7.30832 5.08366ZM13.6333 12.3503H9.25832C8.91666 12.3503 8.63332 12.067 8.63332 11.7253C8.63332 11.3837 8.91666 11.1003 9.25832 11.1003H13.6333C13.9833 11.1003 14.2583 11.3837 14.2583 11.7253C14.2583 12.067 13.9833 12.3503 13.6333 12.3503ZM13.6333 6.51699H9.25832C8.91666 6.51699 8.63332 6.23366 8.63332 5.89199C8.63332 5.55033 8.91666 5.26699 9.25832 5.26699H13.6333C13.9833 5.26699 14.2583 5.55033 14.2583 5.89199C14.2583 6.23366 13.9833 6.51699 13.6333 6.51699Z" fill="#72849A" />
  </svg>

)
const CMS = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 6.18385C14.2417 6.18385 13.625 5.11718 14.375 3.80885C14.8083 3.05052 14.55 2.08385 13.7917 1.65052L12.35 0.825517C11.6917 0.43385 10.8417 0.667183 10.45 1.32552L10.3583 1.48385C9.60832 2.79218 8.37499 2.79218 7.61666 1.48385L7.52499 1.32552C7.14999 0.667183 6.29999 0.43385 5.64166 0.825517L4.19999 1.65052C3.44166 2.08385 3.18332 3.05885 3.61666 3.81718C4.37499 5.11718 3.75832 6.18385 2.24999 6.18385C1.38332 6.18385 0.666656 6.89218 0.666656 7.76718V9.23385C0.666656 10.1005 1.37499 10.8172 2.24999 10.8172C3.75832 10.8172 4.37499 11.8838 3.61666 13.1922C3.18332 13.9505 3.44166 14.9172 4.19999 15.3505L5.64166 16.1755C6.29999 16.5672 7.14999 16.3339 7.54166 15.6755L7.63332 15.5172C8.38332 14.2089 9.61666 14.2089 10.375 15.5172L10.4667 15.6755C10.8583 16.3339 11.7083 16.5672 12.3667 16.1755L13.8083 15.3505C14.5667 14.9172 14.825 13.9422 14.3917 13.1922C13.6333 11.8838 14.25 10.8172 15.7583 10.8172C16.625 10.8172 17.3417 10.1089 17.3417 9.23385V7.76718C17.3333 6.90052 16.625 6.18385 15.75 6.18385ZM8.99999 11.2088C7.50832 11.2088 6.29166 9.99218 6.29166 8.50052C6.29166 7.00885 7.50832 5.79218 8.99999 5.79218C10.4917 5.79218 11.7083 7.00885 11.7083 8.50052C11.7083 9.99218 10.4917 11.2088 8.99999 11.2088Z" fill="#72849A" />
  </svg>


)
const Message = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1667 0.666016H4.83335C2.53335 0.666016 0.666687 2.52435 0.666687 4.81602V9.79935V10.6327C0.666687 12.9244 2.53335 14.7827 4.83335 14.7827H6.08335C6.30835 14.7827 6.60835 14.9327 6.75002 15.116L8.00002 16.7744C8.55002 17.5077 9.45002 17.5077 10 16.7744L11.25 15.116C11.4084 14.9077 11.6584 14.7827 11.9167 14.7827H13.1667C15.4667 14.7827 17.3334 12.9244 17.3334 10.6327V4.81602C17.3334 2.52435 15.4667 0.666016 13.1667 0.666016ZM5.66669 8.99935C5.20002 8.99935 4.83335 8.62435 4.83335 8.16602C4.83335 7.70768 5.20835 7.33268 5.66669 7.33268C6.12502 7.33268 6.50002 7.70768 6.50002 8.16602C6.50002 8.62435 6.13335 8.99935 5.66669 8.99935ZM9.00002 8.99935C8.53335 8.99935 8.16669 8.62435 8.16669 8.16602C8.16669 7.70768 8.54169 7.33268 9.00002 7.33268C9.45835 7.33268 9.83335 7.70768 9.83335 8.16602C9.83335 8.62435 9.46669 8.99935 9.00002 8.99935ZM12.3334 8.99935C11.8667 8.99935 11.5 8.62435 11.5 8.16602C11.5 7.70768 11.875 7.33268 12.3334 7.33268C12.7917 7.33268 13.1667 7.70768 13.1667 8.16602C13.1667 8.62435 12.8 8.99935 12.3334 8.99935Z" fill="#72849A" />
  </svg>

)


const ItemAndServices = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3143_14741)">
      <path d="M19 6H17C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.66 3 15 4.34 15 6H9C9 4.34 10.34 3 12 3ZM19 20H5V8H19V20ZM12 12C10.34 12 9 10.66 9 9H7C7 11.76 9.24 14 12 14C14.76 14 17 11.76 17 9H15C15 10.66 13.66 12 12 12Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3143_14741">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

)
const DeliveryManagement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4614_15931)">
      <path d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM19.5 9.5L21.46 12H17V9.5H19.5ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM8.22 15C7.67 14.39 6.89 14 6 14C5.11 14 4.33 14.39 3.78 15H3V6H15V15H8.22ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_4614_15931">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>


)
const InventoryManagement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_404_4036)">
      <path d="M20 2H4C3 2 2 2.9 2 4V7.01C2 7.73 2.43 8.35 3 8.7V20C3 21.1 4.1 22 5 22H19C19.9 22 21 21.1 21 20V8.7C21.57 8.35 22 7.73 22 7.01V4C22 2.9 21 2 20 2ZM19 20H5V9H19V20ZM20 7H4V4H20V7Z" fill="#72849A" />
      <path d="M15 12H9V14H15V12Z" fill="#72849A" />
    </g>
    <defs>
      <clipPath id="clip0_404_4036">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>


)
const FinanceManagement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM17 14H3V6H17V14ZM10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7ZM23 7V18C23 19.1 22.1 20 21 20H4C4 19 4 19.1 4 18H21V7C22.1 7 22 7 23 7Z" fill="#72849A" />
  </svg>

)
const Reports = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3143_14712)">
      <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#72849A" />
    </g>
    <defs>
      <clipPath id="clip0_3143_14712">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

)

export const DashboardIcon = () => { return <Icon component={Dashboard} /> }
export const UserManagementIcon = () => { return <Icon component={UserManagement} /> }
export const AccountManagementIcon = () => { return <Icon component={AccountManagement} /> }
export const InquiryManagementIcon = () => { return <Icon component={InquiryManagement} /> }
export const AttendanceManagementIcon = () => { return <Icon component={AttendanceManagement} /> }
export const TaskManagementIcon = () => { return <Icon component={TaskManagement} /> }
export const CMSIcon = () => { return <Icon component={CMS} /> }
export const MessageIcon = () => { return <Icon component={Message} /> }
export const ItemAndServicesIcon = () => { return <Icon component={ItemAndServices} /> }
export const DeliveryManagementIcon = () => { return <Icon component={DeliveryManagement} /> }
export const InventoryManagementIcon = () => { return <Icon component={InventoryManagement} /> }
export const FinanceManagementIcon = () => { return <Icon component={FinanceManagement} /> }
export const ReportIcon = () => { return <Icon component={Reports} /> }

