import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  const customer_id = localStorage.getItem('customer_id')
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/attendance-management/daily-attendance`} component={lazy(() => import(`./AttendanceManagement/AttendanceManagement`))} />
        <Route path={`${APP_PREFIX_PATH}/task-management`} component={lazy(() => import(`./TaskManagement/Taskmanagement`))} />
        <Route path={`${APP_PREFIX_PATH}/task-management/schedule`} component={lazy(() => import(`./TaskManagement/Schedule/Schedule`))} />
        <Route path={`${APP_PREFIX_PATH}/finance-management`} component={lazy(() => import(`./FinanceManagement/InvoicesAndPayments/InvoicesAndPayments`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/attendance-management/daily-attendance`} />

        {/* <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} /> */}
        {/* <Route path={`${APP_PREFIX_PATH}/task-management/task/job-sites-tasks/${customer_id}`} component={lazy(() => import(`./TaskManagement/Tasks/JobSiteTasks`))} /> */}
        {/* <Route path={`${APP_PREFIX_PATH}/task-management`} component={lazy(() => import(`./TaskManagement/Taskmanagement`))} /> */}
        {/* <Redirect from={`${APP_PREFIX_PATH}/task-management/task/job-sites-tasks`} to={`${APP_PREFIX_PATH}/task-management/task/job-sites-tasks/${customer_id}`} /> */}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);