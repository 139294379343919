import dashboardIcon from "../assets/speed_black_24dp.svg";
import Icon from "@ant-design/icons"

import { APP_PREFIX_PATH } from "configs/AppConfig";

import {
  DashboardIcon,
  UserManagementIcon,
  AccountManagementIcon,
  InquiryManagementIcon,
  ItemAndServicesIcon,
  DeliveryManagementIcon,
  InventoryManagementIcon,
  ReportIcon,
  AttendanceManagementIcon,
  FinanceManagementIcon,
  TaskManagementIcon,
  CMSIcon,
  MessageIcon,
} from "./svgIcons";
import { FinanceIcon } from "assets/svg/icon";
// import { FinanceManagementIcon } from "assets/svg/icon";



// const dashBoardNavTree = [
//   {
//     key: "dashboard",
//     path: `${APP_PREFIX_PATH}`,
//     title: "Dashboard",
//     // icon: <Icon component={dashboardIcon}/>,
//     icon: DashboardIcon,
//     breadcrumb: false,
//     submenu: [],
//   },

// ]
const customer_id = localStorage.getItem('customer_id')

const dashBoardNavTree = [
  {
    key: "d",
    // path: `${APP_PREFIX_PATH}/dashboard`,
    path: "",
    title: " ",
    icon: "",
    breadcrumb: false,
    submenu: [
      {
        key: "attendance-management",
        // path: `${APP_PREFIX_PATH}/attendance-management`,
        path: `${APP_PREFIX_PATH}/attendance-management/daily-attendance`,
        title: "Attendance Management",
        icon: AttendanceManagementIcon,
        breadcrumb: false,
        submenu: [
          // {
          //   key: "daily-attendance",
          //   path: `${APP_PREFIX_PATH}/attendance-management/daily-attendance`,
          //   title: "Daily Attendance",
            
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
      {
        key: "task-management",
        path: `${APP_PREFIX_PATH}/task-management`,
        title: "Task Management",
        icon: TaskManagementIcon,
        breadcrumb: false,
        submenu: [
          {
            key: "task",
            path: `${APP_PREFIX_PATH}/task-management/task`,
            title: "Task",
            
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "schedule",
            path: `${APP_PREFIX_PATH}/task-management/schedule`,
            title: "Schedule",
           
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "finance-management",
        path: `${APP_PREFIX_PATH}/finance-management`,
        title: "Finance Management",
        icon: FinanceManagementIcon,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "chats",
      //   path: `${APP_PREFIX_PATH}/chats`,
      //   title: "Chats",
      //   icon: MessageIcon,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
