import React from "react";
import { Route, Switch, Redirect, withRouter, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import axios from 'axios';
import { concat } from "lodash";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  const search = useLocation().search;
  const history = useHistory()
  // const token = new URLSearchParams(search).get('token');
  const token= localStorage.getItem('token')
  useBodyClass(`dir-${direction}`);

  React.useEffect(() => {
    console.log("Token: "+token);
    // if (token) {
      // localStorage.setItem("token", token);
    // } 
    // else {
    //   localStorage.setItem("token", 
    //   'Y2xtcWl6eXduMDAwM2J6eGEwMTJhMDQ2YQ.28aIezV6erP1CvirzvqOWN6-lPAI8RPDQyGvcF9s_imKNlcOI0f3tSfWQnaG');
    // }
    // localStorage.removeItem("token");
    // console.log(token);

    // if(url_base.includes(TC_INTERNAL_URL)) {
    //   axios.defaults.baseURL = TC_API_INTERNAL_URL
    // }
    // if(url_base.includes(TC_STAGING_URL)) {
    //   axios.defaults.baseURL = TC_API_STAGING_URL
    // }
    // if(url_base.includes(TC_PROD_URL)) {
    //   axios.defaults.baseURL = TC_API_PROD_URL
    // }
    console.log(axios.defaults.baseURL)

    axios.interceptors.response.use(
      (response) => {
        console.log(response)
        return response
      },
      (error) => {
        // if (error.response.status == '401') {
        //   localStorage.removeItem('token')
        //   alert("Unauthorized/Expired Access, please login again to continue!");
        //   history.push(`'/auth/login'`)
        // }

        return Promise.reject(error)
      },
    )
  }, [])

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            {/* <Redirect to={APP_PREFIX_PATH} /> */}
            {/* {token || tokenTemp ? <Redirect to={APP_PREFIX_PATH} /> : <Redirect to={AUTH_PREFIX_PATH} />} */}
            {token ? <Redirect to={APP_PREFIX_PATH} /> : <Redirect to={AUTH_PREFIX_PATH} />}
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
          {token ? <AppLayout direction={direction} location={location}/> : <Redirect to={AUTH_PREFIX_PATH} />}
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));