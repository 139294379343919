import React from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Avatar } from 'antd';
import Ninedots from "../../assets/apps-svgrepo-com.svg";
import profilePic from "../../assets/Avatar.png";

import "./NavRight.css";
import { Dropdown, Menu,Divider } from "antd";
import { Link } from "react-router-dom";
import { Logouticon,Operation,Hrms } from "assets/Icons/Logouticon";
// import searchIcon from "../../assets/search_black_24dp.svg";
// import notificationIcon from "../../assets/notifications_black_24dp.svg";
// import settingsIcon from "../../assets/settings_black_24dp.svg";

const NavRight = () => {
  let user_name = localStorage.getItem('name')
  let user_email = localStorage.getItem('email')
  let user_image = localStorage.getItem('profile_pic')
  const history = useHistory()
  const onClick = (e) => {
    console.log("click ", e);
    // setCurrent(e.key);
  };

  return (
    <>
      <ul className="nav-right">
        <li>
          {/* <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={
              <Menu onClick={onClick} className="options_container reduceDotBorder">
                <Menu.Item key={1}>
                  <Link
                    style={{ color: "#1a3353" }}
                    className="font-size-normal font-weight-bold"
                    to="/app/dashboard/finance"
                  >
                    <Operation className='headiconsvg' /><span className="ml-2">OPERATIONS</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key={2}>
                  <div
                    style={{ color: "#1a3353" }}
                    className="font-size-normal font-weight-bold"
                    onClick={() => {
                      window.location.href =
                        "http://tc-hrms.reddotapps.com.sg/";
                    }}
                  >
                   <Hrms className='headiconsvg'/> <span className="ml-1">HRMS</span>
                  </div>
                </Menu.Item>
              </Menu>
            }
          >
            <span className="ml-3">
              <img className="ninedot" src={Ninedots} alt=''/>
            </span>
          </Dropdown> */}
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={
              <Menu onClick={onClick} className="options_container reduceAdmincardBorder">
                <div className="topadmindetail">
                  <span className="m-2">
                    {/* <img src={user_image} alt='' style={{width: '40px'}}/> */}
                    <Avatar src={user_image} />
                  </span>
                  <div>
                    <h2>{user_name}</h2>
                    <p>{user_email}</p>
                  </div>
                </div>
                <Divider className="userAdminDivider" />
                <Menu.Item key={1}>
                  <div
                    style={{ color: "#1a3353" }}
                    className="font-size-normal font-weight-bold"
                    onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem("customer_id");
                      localStorage.removeItem("job_sites");
                      localStorage.removeItem("email");
                      localStorage.removeItem("name");
                      localStorage.removeItem("profile_pic");
                      history.push('/');
                    }}
                  >
                    <span className="ml-2 logouticon">
                      {" "}
                      <Logouticon />
                    </span>
                    <span className="ml-2">Logout</span>
                  </div>
                </Menu.Item>
              </Menu>
            }
          >
            <span className="ml-3">
            <Avatar src={user_image} />
              {/* <img src={user_image} alt='' style={{width: '40px'}}/> */}
            </span>
          </Dropdown>
        </li>
      </ul>
    </>
  );
};

export default NavRight;
